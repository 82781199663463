@import "../../../global/scss/config";


@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

/* Progress Bar */
$progress-bar-color: $primary-button-bg-color !default;

// Progress Bar
.progress {
  position: relative;
  height: 4px;
  display: block;
  width: 100%;
  background-color: $light-blue-300;
  border-radius: 2px;
  background-clip: padding-box;
  margin: 0.5rem 0 1rem 0;
  overflow: hidden;
  .determinate {
    position: absolute;
    background-color: inherit;
    top: 0;
    bottom: 0;
    background-color: $progress-bar-color;
    transition: width .3s linear;
  }
  .indeterminate {
    background-color: $progress-bar-color;
    &:before {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left:0;
      bottom: 0;
      will-change: left, right;
      // Custom bezier
      animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;

    }
    &:after {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left:0;
      bottom: 0;
      will-change: left, right;
      // Custom bezier
      animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      animation-delay: 1.15s;
    }
  }
}
@include keyframes(indeterminate) {
  0% {
    left: -35%;
    right:100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@include keyframes(indeterminate-short) {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}