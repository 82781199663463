@import '../../../../global/scss/config';

.posenet-page{
  @include flex(column, center, center);
  width:100%;
  height:100%;
  background:$secondary-background-color;

  .loading-models{
    @include max-size;
    @include flex-center(column);
  }

  .posenet-content{
    @include pad-box(48px 24px);
    width:100%;
  }
}